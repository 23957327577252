.module.viewRoomList {
    height: 100%;
    @include gap-footer(0px);
    position: relative;
    z-index: 1;
    .view-room-list-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 500px;
        width: 100%;
        z-index: -1;
    }
    .view-room-list-body {
        position: absolute;
        top: 400px;
        left: 0;
        right: 0;
        height: 100px;
        width: 100%;
        z-index: -1;
        background-image: linear-gradient(
            to bottom,
            rgba(255, 0, 0, 0) 5%,
            rgba(var(--color_body-rgb), 0.5) 30%,
            rgba(var(--color_body-rgb), 0.7) 50%,
            rgba(var(--color_body-rgb), 1) 100%
        );
    }
    .search-room {
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include gap-header(0);
        // @include media-sm {
        //     @include gap-header(0);
        // }

        // * {
        //     color: var(--color_secondary);
        // }
        .room-header {
            width: 70%;
            margin: 0 auto;
            text-align: center;
            @include media-sm {
                width: 100%;
            }
            h1 {
                // margin-bottom: 1rem;
                color: var(--color_secondary);
            }
            > * {
                margin-bottom: 2rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .list-room {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;
        @include media-lg {
            grid-template-columns: repeat(3, 1fr);
        }
        @include media-md {
            grid-template-columns: repeat(3, 1fr);
        }
        @include media-sm {
            gap: 1rem;
            grid-template-columns: repeat(1, 1fr);
        }

        > * {
            box-shadow: 0 4px 8px 0 var(--color_box_shadow);
            &:hover {
                box-shadow: 0 8px 16px 0 var(--color_box_shadow);
                transform: translateY(-0.5rem);
            }
        }
    }
}
