.module.inputDatePicker {
    // * {
    //     @include no-select();
    // }
    .hide-picker {
        height: 0 !important;
        width: 0 !important;
        overflow: hidden;
    }
    .input-display {
        input {
            cursor: pointer;
            @include no-select();
            @extend .noselect;
        }
        &.disabled {
            pointer-events: none;
            opacity: 0.7;
        }
    }
    .input-date-picker-wrapper {
        padding: 2rem;
        width: 320px;
        * {
            @include no-select();
        }
        .box-control {
            display: flex;
            align-items: center;
            .item-icon {
                flex: 0 0 auto;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                background-color: var(--color_primary);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                i {
                    color: var(--color_secondary);
                    margin: 0 !important;
                    line-height: 1;
                }
            }
            .item-date {
                flex: 1;
                text-align: center;
                color: var(--color_primary);
            }
        }
        .box-table {
            table {
                width: 100%;
                border-collapse: collapse;
                border-spacing: 0;
                table-layout: fixed;
                // * {
                //     @include font-size($font_tiny);
                // }
                td {
                    // padding: 8px 0;
                    border: 1px solid #dfe0e4;
                    text-align: center;
                }
                .tr-header {
                    font-weight: bold;
                    text-align: center;
                    @include font-size($font_tiny);
                    color: var(--color_primary);
                    td {
                        padding: 8px 0;
                    }
                }
                tbody {
                    td {
                        @include font-size($font_tiny * 1.1);
                    }
                    .td-day {
                        height: 35px;
                        cursor: pointer;
                        div {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        &:hover {
                            background-color: var(--color_primary);
                            p {
                                color: var(--color_secondary);
                                opacity: 1;
                            }
                        }
                    }
                    .td-other-month {
                        // color: var(--color_detail);
                        p {
                            opacity: 0.3;
                        }
                    }
                    .td-today {
                        // border-color: var(--color_primary);
                        p {
                            text-decoration: underline solid var(--color_primary) 25%;
                        }
                    }
                    .td-select {
                        // background-color: var(--color_primary);
                        pointer-events: none;
                        p {
                            color: var(--color_primary);
                            font-weight: bold;
                        }
                    }
                    .td-disabled {
                        pointer-events: none;
                        p {
                            opacity: 0.2 !important;
                        }
                    }
                }
            }
        }
        .box-action {
            display: flex;
            > * {
                flex: 1;
                &:first-child {
                    margin-right: 1rem;
                }
            }
        }
        > * {
            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.popup-input-date-picker {
    padding: 0 !important;
    // margin: 0 !important;
    max-width: unset !important;
    border: none !important;
    border-radius: 15px !important;
    &::before {
        display: none !important;
    }
}
