.module.itemEvent {
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    background-color: var(--color_primary);
    .item-event-body {
        padding: 2rem;
        > * {
            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
        p,
        h3,
        h2 {
            color: var(--color_secondary);
        }
        .ie-detail {
            display: flex;
            
            .ie-d-meeting {
                flex: 1;
                overflow: hidden;
                margin-right: 1rem;
                .meeting-subject {
                    margin-bottom: 1rem;
                    @include font-size($font_h2);
                    display: inline;
                    font-weight: bold;
                    color: var(--color_secondary);
                    @include text-overflow(2);
                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 0.5rem;
                    }
                }
                .meeting-detail {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 1rem;
                    @include media-sm {
                        grid-template-columns: repeat(1, 1fr);
                    }
                    .item-meeting-detail {
                        width: 100%;
                    }
                }
            }
            .ie-d-time {
                flex: 0 0 auto;
                .time-box {
                    background-color: var(--color_secondary);
                    border-radius: 20px;
                    width: 100px;
                    height: 100px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    @include media-sm {
                        width: 80px;
                        height: 80px;
                    }
                    > * {
                        font-weight: bold;
                        color: var(--color_event_time);
                    }
                }
            }
        }
        .ie-action {
            text-align: right;
        }
    }
    &.ROOMDISABLE {
        background-color: var(--color_event_disable);
    }
}
