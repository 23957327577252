.module.semanticModal {
    background-color: unset;
    // border-radius: 15px;
    // overflow: hidden;
    > * {
        &:first-child {
            border-top-left-radius: 15px !important;
            border-top-right-radius: 15px !important;
        }
        &:last-child {
            border-bottom-left-radius: 15px !important;
            border-bottom-right-radius: 15px !important;
        }
    }
    .modal-header {
        background-color: var(--color_modal);
        border: none;
        padding: 2rem 2rem 0 2rem;
        // padding-bottom: 0;
        .m-h-wrapper {
            > * {
                font-weight: bold;
            }
        }
    }
    .modal-content {
        background-color: var(--color_modal);
        border: none;
        padding: 2rem;
        > .description {
            padding: 0 !important;
        }
    }
    .modal-action {
        background-color: var(--color_modal);
        border: none;
        padding: 0 2rem 2rem 2rem !important;
        // padding-top: 0;
        .m-a-wrapper {
            &.left {
                text-align: left;
            }
            &.center {
                text-align: center;
            }
            &.right {
                text-align: right;
            }
        }
    }
}
