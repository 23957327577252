.module.viewLoading {
    .view-loading-wrapper {
        &.secondary {
            color: var(--color_secondary);
            .ui.loader {
                &::before {
                    border-color: rgba(var(--color_secondary-rgb), 0.15) !important;
                }
                &::after {
                    border-color: var(--color_secondary) transparent transparent;
                }
            }
        }
    }
}
