.module.itemRoom {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    overflow: hidden;
    background-color: var(--color_secondary);
    .item-room-body {
        display: flex;
        flex-direction: column;
        height: 100%;

        .ir-image {
            flex: 0 0 auto;
            height: 180px;
            overflow: hidden;
            position: relative;
            border-bottom: 1px solid var(--color_divider_detail);
            @include media-sm {
                height: 140px;
            }
            > img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            .room-type {
                position: absolute;
                top: 5px;
                right: 5px;
                background-color: #ffffffa8;
                width: 30px;
                height: 30px;
                padding: 5px;
                border-radius: 5px;
                > img {
                    height: 100%;
                    width: 100%;
                }
            }
            .room-status-response {
                position: absolute;
                width: 100%;
                bottom: 0;
                padding: 5px 0;
                text-align: center;
                background-color: #ffffffa8;
                p {
                    font-weight: bold;
                }
                &.ACCEPTED {
                    color: var(--color_accept);
                }
                &.DECLINED {
                    color: var(--color_decline);
                }
            }
        }
        .ir-body {
            flex: 1;
            overflow: hidden;
            padding: 1rem;

            display: flex;
            flex-direction: column;
            > * {
                margin-bottom: 1rem;
                flex: 0 0 auto;
                &:last-child {
                    margin-bottom: 0;
                    flex: 1;
                    overflow: hidden;
                }
            }

            .ir-detail {
                > * {
                    margin-bottom: 0.5rem;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                h3 {
                    font-weight: bold;
                }
                .text-box {
                    @extend .text-overflow-1;
                }

                .detail-facility {
                    // display: flex;
                    // column-gap: 1rem;
                    // flex-wrap: wrap;
                    // row-gap: 1rem;
                    display: grid;
                    grid-gap: 1rem;
                    grid-template-columns: repeat(auto-fill, 17px);
                    .item-facility {
                        width: 17px;
                        height: 17px;
                        .item-facility-wrapper {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
            .ir-footer {
                overflow: hidden;
                .ir-footer-wrapper {
                    height: 100%;
                    display: flex;
                    align-items: flex-end;
                    .ir-disable {
                        flex: 0 0 auto;
                        .ui.label {
                            @include font-size($font_tiny);
                        }
                    }
                    .ir-action {
                        flex: 1;
                        overflow: hidden;
                        text-align: right;
                    }
                }

                // &:last-child {
                //     .ir-footer-wrapper {
                //         display: flex;
                //         align-items: flex-end;
                //         justify-content: flex-end;
                //     }
                // }
            }
        }

        &.horizontal {
            flex-direction: row;
            .ir-image {
                flex: 1;
                height: 200px;
                overflow: hidden;
                border-bottom: none;
                @include media-sm {
                    height: 150px;
                }
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
            .ir-detail {
                flex: 1;
                > * {
                    margin-bottom: 1rem;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
