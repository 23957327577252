.module.boxIconImageWithText {
    width: 100%;
    .icon-wrapper {
    }
    .image-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        .image-box {
            flex: 0 0 auto;
            height: 18px;
            width: 18px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                height: 100%;
            }
            margin-right: 1rem;
        }
        .text-box {
            flex: 1;
            overflow: hidden;
            .text-box-wrapper {
                width: 100%;
                word-break: break-all;
            }
        }
    }
}
