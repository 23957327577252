.module.viewIntegrationExchange {
    @include gap-header(0);
    @include gap-footer(0px);
    .integration-ex-header {
        padding: 2rem 0;
        text-align: center;
        > * {
            font-weight: bold;
        }
    }
    .integration-ex-body {
        .box-action {
            button {
                min-width: 100px;
            }
        }
    }
}
