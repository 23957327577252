.ui.big.button {
    @include font-size($font_button_big);
}
.ui.large.button {
    @include font-size($font_button_large);
}
.ui.button {
    @include font-size($font_button_medium);
}
.ui.small.button {
    @include font-size($font_button_small);
}
.ui.tiny.button {
    @include font-size($font_button_tiny);
}
.ui.mini.button {
    @include font-size($font_button_mini);
}

// .ui.button {
//     border-radius: 20px !important;
// }
.view-button {
    border-radius: 20px !important;
}
.view-button-icon {
}

// .ui.icon.button {

// }

.button-primary {
    color: var(--color_primary) !important;
    background-color: var(--color_secondary) !important;
    box-shadow: 0 0 0 1px var(--color_primary) inset !important;
}

.button-secondary {
    color: var(--color_secondary) !important;
    background-color: rgba(255, 255, 255, 0) !important;
    box-shadow: 0 0 0 1px var(--color_secondary) inset !important;
}
