.module.viewUser {
    @include gap-header(0);
    @include gap-footer(0px);
    .view-user-header {
        padding: 2rem 0;
        text-align: center;
        > * {
            font-weight: bold;
        }
    }
    .view-user-body {
        .box-detail {
            display: flex;
            p {
                margin: 0;
            }
            .detail-header {
                width: 50%;
                flex: 0 0 auto;
                display: flex;
                margin-right: 1rem;
                p {
                    font-weight: bold;
                    margin-right: 0;
                    flex: 1;
                    &:last-child {
                        flex: 0 0 auto;
                    }
                }
            }
            .detail-content {
                flex: 1;
                overflow: hidden;
                p {
                    word-wrap: break-word;
                }
            }
        }

        .box-user-detail,
        .box-user-information,
        .box-meeting-information,
        .box-o365-information,
        .box-ad-information {
            background-color: var(--color_secondary);
            border-radius: 20px;
            padding: 2rem;
            // box-shadow: 0 4px 8px 0 var(--color_box_shadow);
            // &:hover {
            //     box-shadow: 0 8px 16px 0 var(--color_box_shadow);
            // }
        }
        .box-user-detail {
            display: flex;
            align-items: center;
            position: relative;
            box-shadow: 0 4px 8px 0 var(--color_box_shadow);
            .user-image {
                flex: 0 0 auto;
                margin-right: 3rem;
                @include media-sm {
                    margin-right: 1rem;
                }
                .user-image-wrapper {
                    width: 200px;
                    height: 200px;
                    border-radius: 50%;
                    overflow: hidden;
                    @include media-sm {
                        width: 100px;
                        height: 100px;
                    }
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
            .user-detail {
                flex: 1;
                overflow: hidden;
                margin-top: -10%;
                p {
                    word-wrap: break-word;
                }
            }
            .user-action {
                position: absolute;
                bottom: 1rem;
                right: 1rem;
            }
        }

        .box-user-information {
            margin-top: 2rem;
            .user-information-wrapper {
                > * {
                    margin-bottom: 1rem;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .information-header {
                    p {
                        font-weight: bold;
                        @include font-size($font_body_header);
                        color: var(--color_primary);
                    }
                }
            }
        }

        .box-meeting-information {
            margin-top: 2rem;
            .meeting-information-wrapper {
                > * {
                    margin-bottom: 1rem;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .information-header {
                    p {
                        font-weight: bold;
                        @include font-size($font_body_header);
                        color: var(--color_primary);
                    }
                }
                .box-available-rooms {
                    max-height: 200px;
                    overflow: auto;
                    @extend .scc-scrollbar-custom;
                    > * {
                        margin-bottom: 1rem;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .box-o365-information {
            margin-top: 2rem;
            .o365-information-wrapper {
                > * {
                    margin-bottom: 1rem;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .information-header {
                    p {
                        font-weight: bold;
                        @include font-size($font_body_header);
                        color: var(--color_primary);
                    }
                }
            }
        }

        .box-ad-information {
            margin-top: 2rem;
            .ad-information-wrapper {
                > * {
                    margin-bottom: 1rem;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .information-header {
                    p {
                        font-weight: bold;
                        @include font-size($font_body_header);
                        color: var(--color_primary);
                    }
                }
            }
        }

        // .box-action {
        //     button {
        //         min-width: 100px;
        //     }
        // }
    }
}
