.module.languagePopup {
    width: 250px;
    // overflow: hidden;
    .language-popup-wrapper {
        box-shadow: 0 4px 8px 0 var(--color_box_shadow);
        &:hover {
            box-shadow: 0 8px 16px 0 var(--color_box_shadow);
        }
        .menu-header {
            padding: 2rem;
            background-color: var(--color_primary);
            > * {
                color: var(--color_secondary);
            }
        }
        .menu-body {
            > * {
                padding: 2rem;
                border-bottom: 2px solid var(--color_divider_detail);
                // padding-bottom: 2rem;
                &:last-child {
                    border: none;
                    // padding-bottom: 0;
                }
            }
            .item-menu {
                margin-bottom: 1.5rem;
                &:last-child {
                    margin-bottom: 0;
                }
                .item-box {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    color: inherit;
                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 1rem;
                        object-fit: cover;
                    }
                    &:hover {
                        p {
                            font-weight: bold;
                        }
                    }
                }
                &.active {
                    pointer-events: none;
                    p {
                        color: var(--color_primary);
                        font-weight: bold;
                    }
                }
            }

            .menu-list {
                .menu-list-box {
                }
            }

        }
    }
}
