.module.inputTimePicker {
    .hide-picker {
        height: 0 !important;
        width: 0 !important;
        overflow: hidden;
    }
    .input-display {
        input {
            cursor: pointer;
        }
        &.disabled {
            pointer-events: none;
            opacity: 0.7;
        }
    }

    .input-time-picker-wrapper {
        padding: 2rem;
        width: 280px;
        * {
            @include no-select();
        }
        .box-time-display {
            display: flex;
            align-items: center;
            justify-content: center;
            .time-item {
                background-color: var(--color_primary);
                color: var(--color_secondary);
                width: 60px;
                height: 60px;
                border-radius: 5px;
                @include font-size($font_h2);
                line-height: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
            }
            .time-separator {
                color: var(--color_primary);
                margin: 0 1rem;
                font-weight: bold;
                @include font-size($font_h1);
            }
        }
        .box-control {
            .slider-time {
                width: 100%;
                height: 8px;
                > div {
                    &:first-child {
                        background-color: var(--color_primary);
                    }
                    &:last-child {
                        > div {
                            border: 2px solid var(--color_primary);
                        }
                    }
                }
            }
        }
        .box-action {
            display: flex;
            > * {
                flex: 1;
                &:first-child {
                    margin-right: 1rem;
                }
            }
        }
        > * {
            margin-bottom: 2rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.popup-input-time-picker {
    padding: 0 !important;
    // margin: 0 !important;
    max-width: unset !important;
    border: none !important;
    border-radius: 15px !important;
    &::before {
        display: none !important;
    }
}

.timepicker-spinbox {
    .tui-timepicker {
        padding: 1rem !important;
        border: none !important;
    }
}
