.module.viewRoomInfo {
    height: 100%;
    @include gap-header(20px);
    @include gap-footer(0px);

    .view-room-info-wrapper {
        > * {
            margin-bottom: 2rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .box-room-facility,
        .box-event,
        .box-booking {
            background-color: var(--color_secondary);
            border-radius: 20px;
            padding: 2rem;
            // box-shadow: 0 4px 8px 0 var(--color_box_shadow);
        }
        .box-room {
            .box-item-room {
                margin-bottom: 2rem;
                > * {
                    box-shadow: 0 4px 8px 0 var(--color_box_shadow);
                }
            }
            .box-room-facility {
                .room-facility-wrapper {
                    .facility-header {
                        margin-bottom: 1rem;
                        p {
                            font-weight: bold;
                            @include font-size($font_body_header);
                            color: var(--color_primary);
                        }
                    }
                    .facility-list {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        gap: 1rem;
                        max-height: 110px;
                        overflow: auto;
                        @extend .scrollbar-custom;
                        .module.boxIconImageWithText .image-wrapper .image-box {
                            width: 30px;
                            height: 30px;
                        }
                        @include media-sm {
                            grid-template-columns: repeat(2, 1fr);
                        }
                    }
                    .facility-not-found {
                        text-align: center;
                        color: var(--color_detail);
                    }
                }
            }
        }
        .box-event {
            .event-wrapper {
                .event-header {
                    margin-bottom: 1rem;
                    p {
                        font-weight: bold;
                        @include font-size($font_body_header);
                        color: var(--color_primary);
                    }
                }
                .event-item {
                }
                .event-not-found {
                    text-align: center;
                    color: var(--color_detail);
                }
            }
        }
        .box-booking {
            .booking-wrapper {
                > * {
                    margin-bottom: 1rem;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .booking-header {
                    p {
                        font-weight: bold;
                        @include font-size($font_body_header);
                        color: var(--color_primary);
                    }
                }
                .booking-control {
                    display: flex;
                    > * {
                        margin-right: 1rem;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                    .control-date {
                        flex: 1;
                        overflow: hidden;
                    }
                    .control-time {
                        flex: 2;
                        overflow: hidden;
                        display: flex;
                        .time-item {
                            flex: 1;
                            overflow: hidden;
                            margin-right: 1rem;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                    @include media-sm {
                        flex-direction: column;
                        > * {
                            margin-bottom: 1rem;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                .booking-calendar {
                    height: 60vh;
                    // background-color: rgb(231, 228, 195);
                    .fc-scroller {
                        @extend .scrollbar-custom;
                        &::-webkit-scrollbar-track {
                            border-radius: 0;
                        }
                        &::-webkit-scrollbar-thumb {
                            &:hover {
                            }
                        }
                    }
                    .calendar-slot-label {
                        // font-weight: bold;
                        // color: var(--color_primary);
                    }
                    .calendar-day-label {
                        font-weight: bold;
                        padding: 0.5rem;
                        a {
                            pointer-events: none;
                            color: var(--color_primary);
                        }
                    }
                    .calendar-event-item {
                        background-color: unset;
                        box-shadow: none;
                        border: none;
                        border-radius: 0;
                        cursor: unset;
                    }
                }
                .error-your-select {
                    text-align: center;
                    color: var(--color_error);
                    font-weight: bold;
                }
                .booking-action {
                    text-align: center;
                }
            }
        }
    }
}
