.module.viewChangePassword {
    @include gap-header(0);
    @include gap-footer(0px);
    .change-password-header {
        padding: 2rem 0;
        text-align: center;
        > * {
            font-weight: bold;
        }
    }
    .change-password-body {
        .box-action {
            button {
                min-width: 100px;
            }
        }
    }
}
