.module.viewSearchRoom {
    height: 100%;
    @include gap-header(0);
    @include gap-footer(0px);
    .view-search-wrapper {
        .vsw-header {
            // height: 160px;
            padding-top: 50px;
            margin-bottom: 30px;
            // display: flex;
            // flex-direction: column;
            // align-items: center;
            // justify-content: center;
            > h1 {
                text-align: center;
                font-weight: bold;
                // margin-bottom: 2rem;
            }
        }
        .box-add-location {
            text-align: right;
            p {
                color: var(--color_click);
                cursor: pointer;
            }
        }
        .list-location {
            padding-top: 2rem;
            > * {
                margin-bottom: 2rem;
                box-shadow: 0 4px 8px 0 var(--color_box_shadow);
                &:hover {
                    box-shadow: 0 8px 16px 0 var(--color_box_shadow);
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .view-result-wrapper {
        padding-top: 2rem;
        .result-tab-header {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            .tab-text-header {
                flex: 1;
                overflow: hidden;
                > * {
                    font-weight: bold;
                }
            }
            .tab-date-time {
                flex: 0 0 auto;
                .tab-date-time-wrapper {
                    display: flex;
                    padding: 1rem;
                    border-radius: 15px;
                    align-items: center;
                    background-color: var(--color_secondary);
                    .dt-text {
                        padding-right: 1rem;
                        border-right: 2px solid var(--color_primary);
                        p {
                            margin: 0;
                            margin-bottom: 0.5rem;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                    .dt-action {
                        padding-left: 1rem;
                        cursor: pointer;
                        .dt-action-wrapper {
                            p {
                                color: var(--color_primary);
                                font-weight: bold;
                            }
                        }
                        &.disabled {
                            pointer-events: none;
                            > * {
                                opacity: 0.45;
                            }
                        }
                    }
                }
            }
        }
        .tab-cart {
            .sticky-tab-cart {
                .ui.sticky.fixed {
                    top: 80px !important;
                    @include media-sm {
                        top: 60px !important;
                    }
                }
            }
            .tab-cart-wrapper {
                display: flex;
                justify-content: space-between;
            }
        }
        // @include media-sm {
        //     flex-direction: column;
        //     > * {
        //         margin-bottom: 1rem;
        //         &:last-child {
        //             margin-bottom: 0;
        //         }
        //     }
        // }

        .result-room-list {
            .result-room-list-wrapper {
                .item-location-header {
                    display: flex;
                    align-items: flex-end;
                    margin-bottom: 1rem;
                    .header-text {
                        flex: 0 0 auto;
                        p {
                            font-weight: bold;
                        }
                    }
                    .header-action {
                        flex: 1;
                        overflow: hidden;
                        text-align: right;
                    }
                }
                .item-location {
                    .item-room-list {
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                        gap: 2rem;
                        @include media-lg {
                            grid-template-columns: repeat(3, 1fr);
                        }
                        @include media-md {
                            grid-template-columns: repeat(3, 1fr);
                        }
                        @include media-sm {
                            gap: 1rem;
                            grid-template-columns: repeat(1, 1fr);
                        }
                        > * {
                            box-shadow: 0 4px 8px 0 var(--color_box_shadow);
                            &:hover {
                                box-shadow: 0 8px 16px 0 var(--color_box_shadow);
                                transform: translateY(-0.5rem);
                            }
                        }
                    }
                }
            }
        }
    }
}
