.module.viewHome {
    height: 100%;
    @include gap-footer(0px);
    position: relative;
    z-index: 1;
    .view-home-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 500px;
        width: 100%;
        z-index: -1;
    }
    .view-home-body {
        position: absolute;
        top: 400px;
        left: 0;
        right: 0;
        height: 100px;
        width: 100%;
        z-index: -1;
        background-image: linear-gradient(
            to bottom,
            rgba(255, 0, 0, 0) 5%,
            rgba(var(--color_body-rgb), 0.5) 30%,
            rgba(var(--color_body-rgb), 0.7) 50%,
            rgba(var(--color_body-rgb), 1) 100%
        );
    }
    .book-now {
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // @include media-sm {
        //     height: 400px;
        // }
        @include gap-header(0);

        * {
            color: var(--color_secondary);
        }

        h1 {
            margin-bottom: 2rem;
        }
        h2 {
            text-align: center;
            font-weight: normal;
            margin-bottom: 2rem;
            @include font-size($font_h3);
        }
        .text-select-duration {
            @include media-sm {
                padding: 0 5.5rem;
            }
        }

        .book-duration {
            display: flex;
            justify-content: center;
            .item-duration {
                flex: 0 0 auto;
                min-width: 70px;
                padding: 0.5rem;
                border: 2px solid var(--color_secondary);
                border-radius: 20px;
                margin-right: 5px;
                cursor: pointer;
                text-align: center;
                &:last-child {
                    margin-right: 0;
                }
                p {
                    font-weight: bold;
                }
                &.active {
                    pointer-events: none;
                    background-color: var(--color_secondary);
                    p {
                        color: var(--color_primary);
                    }
                }
            }
        }
        .module.boxLoadingComponent {
            margin-top: 5rem;
        }
    }
    .list-room {
        // margin-top: -150px;
        .list-room-data {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 2rem;
            @include media-lg {
                grid-template-columns: repeat(3, 1fr);
            }
            @include media-md {
                grid-template-columns: repeat(3, 1fr);
            }
            @include media-sm {
                gap: 1rem;
                grid-template-columns: repeat(1, 1fr);
            }

            > * {
                box-shadow: 0 4px 8px 0 var(--color_box_shadow);
                &:hover {
                    box-shadow: 0 8px 16px 0 var(--color_box_shadow);
                    transform: translateY(-0.5rem);
                }
            }
        }
    }
}
