.noselect {
  -webkit-touch-callout: none !important;
  /* iOS Safari */
  -webkit-user-select: none !important;
  /* Safari */
  -khtml-user-select: none !important;
  /* Konqueror HTML */
  -moz-user-select: none !important;
  /* Firefox */
  -ms-user-select: none !important;
  /* Internet Explorer/Edge */
  user-select: none !important;
  /* Non-prefixed version, currently
  supported by Chrome and Opera */
}

@mixin no-select() {
  -webkit-touch-callout: none !important;
  /* iOS Safari */
  -webkit-user-select: none !important;
  /* Safari */
  -khtml-user-select: none !important;
  /* Konqueror HTML */
  -moz-user-select: none !important;
  /* Firefox */
  -ms-user-select: none !important;
  /* Internet Explorer/Edge */
  user-select: none !important;
  /* Non-prefixed version, currently
  supported by Chrome and Opera */
}

@for $i from 1 through 10 {
  .text-overflow-#{$i} {
    display: -webkit-box;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@mixin text-overflow($size) {
  display: -webkit-box;
  -webkit-line-clamp: $size;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.scc-scrollbar-custom,
.scrollbar-custom {
  &::-webkit-scrollbar {
    // width: 0.4vw !important;
    width: 1.2rem !important;
  }
  &::-webkit-scrollbar-button {
  }
  &::-webkit-scrollbar-track {
    background-color: var(--color_quaternary);
    display: inline-block;
    position: relative;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track-piece {
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--color_secondary);
    border: 0.251rem solid var(--color_quaternary);
    // background: none;
    &:hover {
    }
  }
  &::-webkit-scrollbar-corner {
  }
  &::-webkit-resizer {
  }
}
