.module.viewViewEvent {
    @include gap-header(0);
    @include gap-footer(0px);
    .view-event-header {
        padding: 2rem 0;
        text-align: center;
        > * {
            font-weight: bold;
        }
    }
    .view-event-event-action {
        margin: 1rem;
        text-align: right;
    }
    .view-event-body {
        // background-color: var(--color_secondary);
        // padding: 2rem;
        // border-radius: 20px;
        .box-detail {
            display: flex;
            .detail-header {
                width: 30%;
                flex: 0 0 auto;
                display: flex;
                margin-right: 1rem;
                p {
                    font-weight: bold;
                    margin-right: 0;
                    flex: 1;
                    &:last-child {
                        flex: 0 0 auto;
                    }
                }
            }
            .detail-content {
                flex: 1;
                overflow-x: hidden;
                .detail-content-description {
                    width: 100%;
                    // overflow-x: auto;
                    padding: 5px 0;
                }
                .detail-content-subject {
                    display: inline;
                    // @include text-overflow(2);
                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 0.5rem;
                    }
                }
            }
        }

        .box-location,
        .box-event-detail,
        .box-attendee,
        .box-action-organizer,
        .box-action-attendee {
            background-color: var(--color_secondary);
            border-radius: 20px;
            padding: 2rem;
            // box-shadow: 0 4px 8px 0 var(--color_box_shadow);
            // &:hover {
            //     box-shadow: 0 8px 16px 0 var(--color_box_shadow);
            // }
        }

        .box-location {
            padding: 1rem;
            .location-box {
                // overflow: hidden;
                > p {
                    padding: 0 1rem;
                }
                .room-list {
                    margin-top: 1rem;
                    padding: 1rem;
                    // display: grid;
                    // grid-template-columns: repeat(3, 1fr);
                    // gap: 1rem;
                    max-height: 40vh;
                    overflow: auto;
                    @extend .scrollbar-custom;
                    @include media-sm {
                        max-height: 30vh;
                        // gap: 1rem;
                        // grid-template-columns: repeat(1, 1fr);
                    }
                    // @include media-lg {
                    //     grid-template-columns: repeat(3, 1fr);
                    // }
                    // @include media-md {
                    //     grid-template-columns: repeat(3, 1fr);
                    // }
                    // @include media-lg {
                    //     grid-template-columns: repeat(3, 1fr);
                    // }
                    .item-room {
                        // padding: 1rem;
                        margin-bottom: 1rem;
                        > * {
                            box-shadow: 0 4px 8px 0 var(--color_box_shadow);
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .box-event-detail {
            margin-top: 2rem;
        }
        .box-attendee {
            margin-top: 2rem;
            .box-header {
                margin-bottom: 1rem;
                display: flex;
                border-bottom: 1px solid var(--color_primary);
                padding-bottom: 1rem;
                .item-header {
                    flex: 1 1 auto;
                    cursor: pointer;
                    text-align: center;
                    p {
                        color: var(--color_detail);
                        // font-weight: bold;
                    }
                    &.active {
                        pointer-events: none;
                        p {
                            // font-weight: bold;
                            color: var(--color_primary);
                        }
                    }
                }
            }
            .box-attendee-list {
                overflow: auto;
                @extend .scrollbar-custom;
                .item-attendee {
                    display: flex;
                    padding: 0.5rem 0;
                    // border-bottom: 1px solid var(--color_primary);
                    // &:first-child {
                    //     padding-top: 0;
                    // }
                    &:last-child {
                        // padding-bottom: 0;
                        border: none;
                    }
                    .attendee-type {
                        // height: 100%;
                        margin-right: 1rem;
                        width: 15px;
                        flex: 0 0 auto;
                        border-radius: 2px;
                        &.required {
                            background-color: var(--color_attendee_require);
                        }
                        &.optional {
                            background-color: var(--color_attendee_optional);
                        }
                    }
                    .attendee-data {
                        flex: 1;
                        overflow: hidden;
                        padding: 0.5rem 0;
                        > p {
                            margin-bottom: 0.5rem;
                            font-weight: bold;
                        }
                        .module.boxIconImageWithText {
                            p {
                                color: var(--color_detail);
                            }
                        }
                    }
                    .attendee-status {
                        height: 100%;
                        flex: 0 0 auto;
                    }
                }
            }
            .box-no-attendee {
                text-align: center;
                padding: 1rem;
                color: var(--color_detail);
            }
            .box-attendee-list,
            .box-no-attendee {
                height: 25vh;
                @include media-sm {
                    height: 15vh;
                }
            }
        }
        .box-action-organizer,
        .box-action-attendee {
            margin-top: 2rem;
            display: flex;
            flex-flow: wrap;
            row-gap: 1rem;
            column-gap: 0.5rem;
            justify-content: center;
            > * {
                flex: 0 0 auto;
                // width: 100%;
            }
        }
    }
}
