.module.createUpdateEventForm {
    .box-subject {
        display: inline-block;
        > * {
            display: inline-block;
            &:first-child {
                margin-right: 2rem;
            }
        }
    }
    .box-select-attendee {
        background-color: var(--color_secondary);
        padding: 1rem;
        margin-top: 0.5rem;
        border-radius: 20px;
        border: 1px solid var(--color_primary);
        .select-attendee-wrapper {
            max-height: 200px;
            overflow: auto;
            min-height: 80px;
            @include media-sm {
                min-height: 50px;
                max-height: 150px;
            }
            @extend .scrollbar-custom;
            .please-select {
                padding: 2rem;
                text-align: center;
                p {
                    color: var(--color_detail);
                }
            }
            .attendee-list {
                .item-select-attendee {
                    display: flex;
                    align-items: center;
                    padding: 1rem 0;
                    cursor: pointer;

                    .isa-checkbox {
                        flex: 0 0 auto;
                        margin-right: 1rem;
                    }
                    .isa-text {
                        flex: 1;
                        overflow: hidden;
                        .text-name {
                            margin-bottom: 0.25rem;
                            // font-weight: bold;
                        }
                        .text-email {
                            color: var(--color_detail);
                        }
                    }
                    .isa-action {
                        flex: 0 0 auto;
                        margin-left: 1rem;
                        padding-right: 1rem;
                    }
                }
                .item-select-attendee {
                    // margin-bottom: 1rem;
                    border-bottom: 1px solid var(--color_primary);
                    &:last-child {
                        border-bottom: none;
                        padding-bottom: 0;
                    }
                    &:first-child {
                        padding-top: 0;
                    }
                }
            }
        }
    }
}
