@mixin font-size($size) {
    font-size: $size * (1rem * 1);
    @include media-sm {
        font-size: $size * (1rem * 0.8);
    }
}
@mixin font-size-important($size) {
    font-size: $size * (1rem * 1) !important;
    @include media-sm {
        font-size: $size * (1rem * 0.8) !important;
    }
}
$font_mini: 0.8;
$font_tiny: 1.2;
$font_body: 1.6;
$font_body_header: 1.8;
$font_h1: 3.2;
$font_h2: 2.6;
$font_h3: 2;

$font_button_mini: 0.8;
$font_button_tiny: 1;
$font_button_small: 1.2;
$font_button_medium: 1.4;
$font_button_large: 1.6;
$font_button_big: 1.8;
