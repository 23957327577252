.module.viewContact {
    // width: 300px;
    // overflow: hidden;
    .contact-wrapper {
        @include gap-header(0);
        @include gap-footer(0px);
        .contact-header {
            padding-top: 50px;
            margin-bottom: 30px;
            text-align: center;
            > * {
                font-weight: bold;
            }
        }
        .image-logo {
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;
            img {
                height: 100px;
                max-width: 100%;
                object-fit: cover;
            }
        }
    }
}
