.module.modalCreateUpdateEventSuccess {
    .modal-create-update-event-success {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // padding: 2rem;
        .event-detail {
            margin-bottom: 2rem;
            text-align: center;
            i {
                font-size: 3em;
                margin: 0;
            }
        }
        .event-action {
            text-align: center;
            button {
                min-width: 170px;
            }
        }
    }
}
