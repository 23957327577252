$colorDefault: (
    color_body: #f1f1f3,

    color_primary: #3b76fe,
    color_secondary: #fff,
    color_tertiary: #000,
    color_quaternary: #94b2fb,

    color_event_time: #ed7575,
    color_box_shadow: #00000033,
    color_error: #D81515,
    color_detail: #888,
    color_modal: #f1f1f3,
    color_click: #3b76fe,

    color_event_disable: #838383,
    color_event: #D81515,

    color_divider_detail: #f3f3f3,

    color_attendee_require: #3b76fe,
    color_attendee_optional: #888,

    color_accept: #008000,
    color_decline: #D81515,
);

$themes: (
    default: (
        color: $colorDefault,
    ),
);

@function hexToRGB($hex) {
    @return red($hex), green($hex), blue($hex);
}

@each $theme, $data in $themes {
    $colors: map-get($data, color);
    @if $colors {
        @if ($theme == "default") {
            body {
                @each $name, $value in $colors {
                    --#{$name}: #{$value};
                    --#{$name}-rgb: #{hexToRGB($value)};
                }
            }
        } @else {
            body.#{$theme} {
                @each $name, $value in $colors {
                    --#{$name}: #{$value};
                    --#{$name}-rgb: #{hexToRGB($value)};
                }
            }
        }
    }
}
