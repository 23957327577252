.module.viewResetPassword {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .view-reset-password-wrapper {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        position: relative;
        overflow: hidden;

        .view-reset-password-bg-layer {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.4);
            .ui.container {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .box-content {
                    width: 100%;
                    background: rgba(var(--color_primary-rgb), 0.2);
                    box-shadow: -1px 4px 28px 0px rgba(0, 0, 0, 0.75);
                    padding: 3rem 2rem;
                    border-radius: 20px;
                    backdrop-filter: blur(5px);

                    .box-content-wrapper {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        > * {
                            margin-bottom: 2rem;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                        .content-logo {
                            flex: 0 0 auto;
                            height: 50px;
                            img {
                                height: 100%;
                            }
                        }

                        .content-loading {
                            flex: 0 0 auto;
                            margin-top: 5rem;
                        }

                        .content-success {
                            color: var(--color_secondary);
                            text-align: center;
                            i {
                                margin: 0;
                                margin-bottom: 1rem;
                            }
                            b {
                                cursor: pointer;
                            }
                        }

                        .content-fail {
                            color: var(--color_secondary);
                            text-align: center;
                            i {
                                margin: 0;
                                margin-bottom: 1rem;
                            }
                            b {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}
