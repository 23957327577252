.module.layoutWithHeaderFooter {
    width: 100%;
    // height: 100%;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    background-color: var(--color_body);
    .lwhf-body {
        .lwfr-header {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            box-shadow: 0 4px 8px 0 var(--color_box_shadow);
            z-index: 999;
        }
        .lwfr-footer {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            box-shadow: 0 4px 8px 0 var(--color_box_shadow);
            z-index: 999;
        }
    }
}
