.module.viewChangeUserInformation {
    @include gap-header(0);
    @include gap-footer(0px);
    .change-user-information-header {
        padding: 2rem 0;
        text-align: center;
        > * {
            font-weight: bold;
        }
    }
    .change-user-information-body {
        .user-image {
            display: flex;
            justify-content: center;
            .user-image-wrapper {
                height: 200px;
                width: 200px;
                border-radius: 50%;
                overflow: hidden;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
        .box-action {
            button {
                min-width: 100px;
            }
        }
    }
}
