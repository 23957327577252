.module.viewUserQRCode {
    @include gap-header(0);
    @include gap-footer(0px);
    .view-user-qr-code-header {
        padding-top: 50px;
        margin-bottom: 30px;
        text-align: center;
        > * {
            font-weight: bold;
        }
    }
    .view-user-qr-code-body {
        display: flex;
        justify-content: center;
        .qr-code {
            width: 400px;
            overflow: hidden;
            flex: 0 0 auto;
            @include media-sm {
                width: 100%;
            }
            img {
                width: 100%;
            }
        }
    }
}
