.module.modalRoomSelected {
    .modal-room-selected {
        .box-room-list {
            // border: 1px solid var(--color_primary);
            padding: 0 1rem;
            border-radius: 20px;
            > * {
                // margin-bottom: 1rem;
                border-bottom: 1px solid var(--color_primary);
                &:last-child {
                    border-bottom: none;
                    // margin-bottom: 0;
                }
            }
            .item-room-select {
                display: flex;
                align-items: center;
                padding: 1rem 0;
                .room-text {
                    flex: 1;
                    overflow: hidden;
                    > p {
                        font-weight: bold;
                    }
                    > * {
                        margin-bottom: 0.5rem;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                .room-action {
                    flex: 0 0 auto;
                }
            }
        }
    }
}
