.module.itemEventCalendar {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .item-event-wrapper {
        width: 100%;
        height: 100%;
        
        background-color: var(--color_event);
        p {
            margin: 0;
        }
        .event-text {
            width: 100%;
            height: 100%;
            display: flex;
            padding: 0.5rem 0 0 0.5rem;
            > * {
                margin-right: 0.5rem;
                &:last-child {
                    margin-right: 0;
                }
            }
            p {
                color: var(--color_secondary);
            }
            &.small {
                padding-top: 0.25rem;
                p {
                    @include font-size($font_mini);
                }
            }
        }
        &.YOUR_SELECT {
            background-color: var(--color_primary);
        }
        &.ROOM_DISABLE {
            background-color: var(--color_event_disable);
        }
    }
}
