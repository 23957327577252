.module.labelInput {
    margin-bottom: 0.25rem;
    &:last-child {
        margin-bottom: 0;
    }
    .label-input-wrapper {
        padding-left: 1em;
        > label {
            font-weight: bold;
            @include font-size($font_body);
        }
        span {
            color: var(--color_error);
            font-weight: bold;
        }
    }
}
