.module.modalAddSearchLocation {
    .modal-add-search-location {
        .box-facility {
            background-color: var(--color_secondary);
            border: 1px solid var(--color_primary);
            padding: 1rem 1rem;
            border-radius: 10px;
            .box-facility-wrapper {
                max-height: 150px;
                width: 100%;
                overflow: auto;
                @extend .scc-scrollbar-custom;
                > * {
                    margin-bottom: 0.5rem;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .item-facility {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    .if-checkbox {
                        flex: 0 0 auto;
                        margin-right: 1rem;
                        > * {
                            line-height: 1;
                        }
                    }
                    .if-image {
                        flex: 0 0 auto;
                        margin-right: 1rem;
                        .if-image-wrapper {
                            // width: 16px;
                            height: 17px;
                            img {
                                vertical-align: unset;
                                height: 100%;
                                // width: 100%;
                            }
                        }
                    }
                    .if-text {
                        flex: 1;
                        overflow: hidden;
                        .if-text-wrapper {
                            p {
                            }
                        }
                    }
                }
            }
        }
    }
}
