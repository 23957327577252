* {
    box-sizing: border-box !important;
}

html {
    font-size: 62.5% !important; /*1 rem = 10px*/
}

body {
    font-size: 1.6em;
}

// html,
// body {
//     height: 100%;
// }

// body {
//     > div {
//         height: 100%;
//     }
// }
iframe {
    pointer-events: none;
}

html,
body,
*::after,
*::before {
    margin: 0;
}

body,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
.ui.input,
.ui.form,
.ui.form textarea,
.ui.input input,
.ui.checkbox,
.ui.dropdown,
button,
div,
span,
.ui.form .field > label,
.ui[class*="left icon"].input > input,
select {
    font-family: "prompt" !important;
}

div,
p,
b,
input,
.ui.fluid,
.ui.form,
.ui.checkbox,
.ui.dropdown .menu > .item {
    @include font-size($font_body);
}
.ui.form .field .prompt.label {
    @include font-size-important($font_body);
}

h1 {
    @include font-size($font_h1);
}
h2 {
    @include font-size($font_h2);
}
h3 {
    @include font-size($font_h3);
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0;
    // line-height: 1;
    // margin-bottom: 0.5rem;
    // &:last-child {
    //     margin-bottom: 0;
    // }
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
label {
    @extend .noselect;
}

img {
    text-indent: -9999px;
}

img {
    vertical-align: unset;
}
