.module.viewHeader {
    background-color: var(--color_primary);

    .vh-body {
        height: $size_height_header_lg;
        @include media-md {
            height: $size_height_header_sm;
        }
        display: flex;
        align-items: center;
        .logo {
            flex: 0;
            padding: 1rem;
            height: 100%;
            cursor: pointer;
            // display: flex;
            // align-items: center;
            .logo-wrapper {
                height: 100%;
                img {
                    height: 100%;
                }
            }
        }
        .menu {
            flex: 1;
            overflow: hidden;
            height: 100%;
            // display: flex;
            // justify-content: flex-end;
            // padding: 0.25rem 0;
            .menu-wrapper {
                height: 100%;
                display: flex;
                justify-content: flex-end;
                padding: 0 1rem;
                .menu-item {
                    flex: 0 0 auto;
                    height: 100%;
                    margin-right: 0.5rem;
                    margin-bottom: 0;
                    &:last-child {
                        margin-right: 0;
                    }
                    @include media-sm {
                        display: none;
                    }
                    .menu-item-wrapper {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        padding: 5px 0rem 2px 0rem;
                        cursor: pointer;
                        min-width: 70px;
                        border-bottom: 3px solid rgba(255, 255, 255, 0);
                        .mi-img {
                            flex: 0 0 auto;
                            margin-bottom: 5px;
                            height: 35px;
                            width: 35px;

                            border-radius: 50%;
                            overflow: hidden;
                            padding: 5px;
                            border: 2px solid var(--color_secondary);
                            @include media-sm {
                                height: 30px;
                                width: 30px;
                            }
                            img {
                                height: 100%;
                                width: 100%;
                            }
                        }
                        .mi-text {
                            flex: 0 0 auto;
                            p {
                                line-height: 1;
                                color: var(--color_secondary);
                                @include font-size($font_mini);
                            }
                            @include media-xl {
                                p {
                                    @include font-size($font_tiny);
                                }
                            }
                            @include media-xxl {
                                p {
                                    @include font-size($font_tiny);
                                }
                            }
                        }

                        @include media-sm {
                            min-width: auto;
                        }
                        &.active,
                        &:hover {
                            border-bottom: 3px solid var(--color_secondary);
                            transition: border 500ms ease-out;
                        }
                    }

                    &.mobile-only {
                        display: none;
                    }

                    &.mobile,
                    &.mobile-only {
                        // margin-right: 2rem;
                        &:last-child {
                            margin-right: 0;
                        }
                        .menu-item-wrapper {
                            padding: 5px 0rem 2px 0rem;
                        }
                        @include media-sm {
                            display: block;
                            .mi-img {
                                margin: 0;
                            }
                            p {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

@mixin gap-header($size) {
    padding-top: $size_height_header_lg + $size;
    @include media-md {
        padding-top: $size_height_header_sm + $size;
    }
}

.gap-header {
    @include gap-header(0);
}
