.module.itemCriteriaSearchRoom {
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    background-color: var(--color_secondary);
    border: 2px solid var(--color_primary);
    .item-body {
        padding: 2rem;
        position: relative;
        display: flex;
        .ir-action {
            position: absolute;
            bottom: 0.5rem;
            right: 0.5rem;
            // .action-box {
            //     display: flex;
            //     column-gap: 0.5rem;
            //     .item-action {
            //         flex: 0 0 auto;
            //         cursor: pointer;
            //         i {
            //             color: var(--color_primary);
            //             line-height: 1;
            //             margin: 0;
            //         }
            //     }
            // }
        }
        .ir-image {
            flex: 0 0 auto;
            margin-right: 2rem;
            .box-image {
                width: 150px;
                height: 150px;
                border-radius: 50%;
                overflow: hidden;
                @include media-sm {
                    width: 80px;
                    height: 80px;
                }
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
                &.no-location {
                    background-color: var(--color_divider_detail);
                    padding: 20%;
                }
            }
        }
        .ir-detail {
            flex: 1;
            .facilities-box {
                display: flex;
                flex-flow: wrap;
                row-gap: 0.5rem;
                column-gap: 0.5rem;
                align-items: center;
                > p {
                    margin: 0;
                }
                .item-facility {
                    .facility-box {
                        display: flex;
                        background-color: var(--color_divider_detail);
                        border-radius: 0.5rem;
                        padding: 0.5rem;
                        align-items: center;
                        img {
                            flex: 0 0 auto;
                            width: 18px;
                            height: 18px;
                            object-fit: cover;
                            margin-right: 1rem;
                        }
                        p {
                            @include font-size($font_tiny);
                        }
                    }
                }
            }
        }
    }
}
