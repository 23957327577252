.module.inputHtml {
    .demo-wrapper {
        border-radius: 10px !important;
        border: 1px solid var(--color_primary) !important;
        overflow: hidden;
    }
    .rdw-editor-toolbar {
        margin-bottom: 0;
    }
    .demo-editor {
        padding: 1rem 0 1rem 1rem;
        background-color: #fff;
        max-height: 300px;
        min-height: 100px;
        overflow: auto;
        @extend .scrollbar-custom;
        @include media-sm {
            max-height: 150px;
        }
    }
    .public-DraftStyleDefault-block {
        margin: 1rem 0;
    }
}
