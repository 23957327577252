.module.boxLoadingComponent {
    width: 100%;
    // height: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &.inverted {
        .ui.loader {
            &::before {
                border-color: rgba(0, 0, 0, 0.1);
            }
            &::after {
                border-color: #767676 transparent transparent;
            }
        }
    }
}
