.module.viewFooter {
    background-color: var(--color_primary);
    display: none;
    @include media-sm {
        display: block;
    }
    .vh-body {
        height: $size_height_footer_mobile;
        .menu {
            overflow: hidden;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;

            a,
            .a-item {
                flex: 1;
                height: 100%;
                margin-right: 0.5rem;
                &:last-child {
                    margin-right: 0;
                }
                .menu-item {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 5px 0;
                    cursor: pointer;
                    .mi-img {
                        flex: 0 0 auto;
                        overflow: hidden;
                        margin-bottom: 0.5rem;
                        width: 35px;
                        height: 35px;

                        border-radius: 50%;
                        overflow: hidden;
                        padding: 5px;
                        border: 2px solid var(--color_secondary);
                        @include media-sm {
                            height: 30px;
                            width: 30px;
                            margin-bottom: 0.75rem;
                        }
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    p {
                        line-height: 1;
                        flex: 0 0 auto;
                        color: var(--color_secondary);
                        @include font-size($font_tiny);
                    }

                    
                }
            }
        }
    }
}

// .gap-footer {
//     padding-top: $size_height_footer_mobile;
// }

@mixin gap-footer($size) {
    padding-bottom: $size_height_footer_mobile + $size;
    @include media-sm {
        padding-bottom: $size_height_footer_mobile + $size_height_footer_mobile + $size;
    }
}

.gap-footer {
    @include gap-footer(0);
}
