.ui.button {
    margin-right: 1rem;
    &:last-child {
        margin-right: 0;
    }
}

.grid-half-padding,
.ui.grid {
    > .row {
        &:not(:last-child) {
            padding-bottom: 0 !important;
        }
    }
    > .column:not(.row) {
        &:not(:last-child) {
            padding-bottom: 0 !important;
        }
    }
}

.ui.input {
    border-radius: 30px;
    overflow: hidden;
    // box-shadow: 0 0 0 1px var(--color_primary) inset !important;
    input {
        line-height: 1 !important;
        border: none !important;
        border-radius: 30px !important;
        box-shadow: 0 0 0 1px var(--color_primary) inset !important;
    }
}

.ui.checkbox {
    font-weight: bold;
}

.ui.form textarea {
    border-radius: 10px !important;
    border: 1px solid var(--color_primary) !important;
    @extend .scrollbar-custom;
}

.ui.selection.dropdown {
    border-radius: 20px !important;
    // overflow: hidden;
    border: 1px solid var(--color_primary);
}
.ui.active.selection.dropdown {
    border-radius: 20px !important;
}

.ui.form .field {
    margin: 0;
}

.ui.modal .scrolling.content {
    // max-height: 90vh;
    @extend .scc-scrollbar-custom;
}

// .ui.divider {
//     border-color: var(--color_primary) !important;
// }

.popup-blank {
    padding: 0 !important;
    max-width: unset !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    // margin: 0 !important;
    &::before {
        display: none !important;
    }
}
