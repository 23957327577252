.module.viewFAQ {
    // width: 300px;
    // overflow: hidden;
    .faq-wrapper {
        @include gap-header(0);
        @include gap-footer(0px);
        .faq-header {
            padding-top: 50px;
            margin-bottom: 30px;
            text-align: center;
            > * {
                font-weight: bold;
            }
        }
    }
}
