.module.viewLogin {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .view-login-wrapper {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        // animation: slideleft 20000s infinite linear;
        background-attachment: fixed;
        position: relative;
        overflow: hidden;
        // @keyframes slideleft {
        //     from {
        //         background-position: 0%;
        //     }
        //     to {
        //         background-position: 1000%;
        //     }
        // }

        .view-login-bg-layer {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.4);
            .ui.container {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                p,
                h1,
                h3,
                label {
                    color: var(--color_secondary);
                }
                .box-login {
                    width: 400px;
                    background: rgba(var(--color_primary-rgb), 0.2);
                    box-shadow: -1px 4px 28px 0px rgba(0, 0, 0, 0.75);
                    padding: 3rem 2rem;
                    border-radius: 20px;
                    backdrop-filter: blur(5px);
                    @include media-sm {
                        width: 100%;
                    }
                    .box-information {
                        text-align: center;
                        margin-bottom: 2rem;
                        display: flex;
                        flex-direction: column;
                        &:last-child {
                            margin: 0;
                        }
                        .bf-logo {
                            margin-bottom: 2rem;
                            height: 50px;
                            img {
                                height: 100%;
                            }
                        }
                    }
                    .box-mail-system {
                        max-height: 0;
                        overflow: hidden;
                        transition: max-height 0.3s ease-out;
                        width: 100%;
                        &.open {
                            max-height: 400px;
                            overflow: unset;
                        }
                        .system-form {
                            margin-bottom: 1rem;
                            h3 {
                                font-weight: bold;
                                text-align: center;
                                margin-bottom: 1rem;
                            }
                        }
                        .system-action {
                            text-align: center;
                            button {
                                width: 50%;
                                margin-bottom: 1rem;
                            }
                        }
                    }
                    .box-login-or {
                        // padding: 5px 0;
                        margin-bottom: 1rem;
                        .ui.divider {
                            color: var(--color_secondary);
                            margin: 0;
                        }
                    }
                    .box-action-login {
                        margin-bottom: 1rem;
                        &.no-system {
                            margin-bottom: 0;
                        }
                        button {
                            width: 100%;
                        }
                    }
                    .box-local-login {
                        max-height: 0;
                        overflow: hidden;
                        transition: max-height 0.3s ease-out;
                        width: 100%;
                        &.open {
                            max-height: 400px;
                            overflow: unset;
                        }

                        > * {
                            margin-bottom: 2rem;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                        .local-action {
                            text-align: center;
                            button {
                                width: 50%;
                                margin-left: auto;
                                margin-right: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}
