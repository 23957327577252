.module.inputFile {
    .input-file-wrapper {
        .box-input-display {
            .disabled.field {
                opacity: 1 !important;
                pointer-events: unset !important;
                .ui.disabled.input {
                    opacity: 1 !important;
                    pointer-events: unset !important;
                    button {
                        pointer-events: unset !important;
                    }
                }
            }
        }
        .box-input-file {
            max-height: 0;
            overflow: hidden;
            margin-bottom: 0;
        }
    }
}
