.module.viewTerms {
    // width: 300px;
    // overflow: hidden;
    .terms-wrapper {
        @include gap-header(0);
        @include gap-footer(0px);
        .terms-header {
            padding-top: 50px;
            margin-bottom: 30px;
            text-align: center;
            > * {
                font-weight: bold;
            }
        }
    }
}
