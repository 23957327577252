.module.layoutBlankHeightMax {
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    .lbhm-body {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
}
