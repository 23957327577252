.module.viewMyReservation {
    height: 100%;
    @include gap-header(0);
    @include gap-footer(0px);
    .mr-header {
        padding-top: 50px;
        margin-bottom: 30px;
        // height: 160px;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;
        > h1 {
            text-align: center;
            font-weight: bold;
            margin-bottom: 2rem;
        }
        .mr-control-date {
            width: 60%;
            margin: 0 auto 0 auto;
            align-items: center;
            @include media-sm {
                width: 100%;
            }
            display: flex;
            .arrow-control {
                flex: 0 0 auto;
                &:first-child {
                    margin-right: 1rem;
                }
                &:last-child {
                    margin-left: 1rem;
                }
            }
            .date-control {
                flex: 1;
                overflow: hidden;
                input {
                    color: var(--color_primary);
                    text-align: center;
                }
            }
        }
    }

    .list-event {
        > * {
            margin-bottom: 2rem;
            &:last-child {
                margin-bottom: 0;
            }
            box-shadow: 0 4px 8px 0 var(--color_box_shadow);
            &:hover {
                box-shadow: 0 8px 16px 0 var(--color_box_shadow);
            }
        }
    }
}
