.module.menuPopup {
    width: 400px;
    // overflow: hidden;
    .menu-popup-wrapper {
        box-shadow: 0 4px 8px 0 var(--color_box_shadow);
        &:hover {
            box-shadow: 0 8px 16px 0 var(--color_box_shadow);
        }
        .menu-header {
            padding: 2rem;
            background-color: var(--color_primary);
            > * {
                color: var(--color_secondary);
            }
        }
        .menu-body {
            > * {
                padding: 2rem;
                border-bottom: 2px solid var(--color_divider_detail);
                // padding-bottom: 2rem;
                &:last-child {
                    border: none;
                    // padding-bottom: 0;
                }
            }
            .menu-user {
                cursor: pointer;
                padding: 1rem;
                &:hover {
                    box-shadow: 0 8px 16px 0 var(--color_box_shadow);
                }
                .user-box {
                    display: flex;
                    align-items: center;
                    img {
                        flex: 0 0 auto;
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;
                        overflow: hidden;
                        object-fit: cover;
                        margin-right: 1rem;
                    }
                    .user-info {
                        flex: 1;
                        overflow: hidden;
                        > * {
                            margin: 0;
                            word-wrap: break-word;
                        }
                        p {
                            color: var(--color_tertiary);
                        }
                        .text-header {
                            font-weight: bold;
                            // @include font-size($font_body_header);
                        }
                        .user-action {
                            margin-top: 0.5rem;
                            text-align: right;
                        }
                    }
                }
            }

            .item-menu {
                margin-bottom: 1.5rem;
                &:last-child {
                    margin-bottom: 0;
                }
                .item-box {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    color: inherit;
                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 1rem;
                        object-fit: cover;
                    }
                    &:hover {
                        p {
                            font-weight: bold;
                        }
                    }
                }
            }

            .menu-list {
                .menu-list-box {
                }
            }

            .menu-logout {
                .menu-logout-box {
                }
            }
        }
    }
}
