.module.contentErrorModal {
    .box-content {
        margin-bottom: 1rem;
    }
    .box-code {
        > * {
            opacity: 0.7;
            font-size: 0.7em;
        }
    }
}
