.module.viewUserIntegration {
    @include gap-header(0);
    @include gap-footer(0px);
    .view-user-integration-header {
        padding: 2rem 0;
        text-align: center;
        > * {
            font-weight: bold;
        }
    }
    .view-user-integration-body {
        .integration-wrapper {
            background-color: var(--color_secondary);
            border-radius: 20px;
            padding: 2rem;
            // box-shadow: 0 4px 8px 0 var(--color_box_shadow);
            // &:hover {
            //     box-shadow: 0 8px 16px 0 var(--color_box_shadow);
            // }
            .integration-header {
                p {
                    font-weight: bold;
                    @include font-size($font_body_header);
                    color: var(--color_primary);
                }
            }
            .integration-body {
                display: flex;
                align-items: center;
                .ib-text {
                    flex: 1;
                    overflow: hidden;
                    .no-integration {
                        color: var(--color_error);
                    }
                }
                .ib-action {
                    flex: 0 0 auto;
                }
                @include media-sm {
                    // flex-direction: column;
                    // align-items: flex-start;
                    display: block;
                    margin-top: 1rem;
                    > * {
                        margin-bottom: 1rem;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    .ib-action {
                        text-align: right;
                    }
                }
            }
        }
    }
}
