.module.modalAttendee {
    .modal-attendee-wrapper {
        .box-header {
            display: flex;
            align-items: flex-end;
            .header-text {
                flex: 1;
                overflow: hidden;
            }
            .header-action {
                text-align: right;
                flex: 1;
                overflow: hidden;
            }
        }
        .box-select-attendee {
            margin-top: 1rem;
            .select-search {
                display: flex;
                align-items: center;
                .search-input {
                    flex: 1;
                    overflow: hidden;
                    margin-right: 1rem;
                }
                .header-action {
                    flex: 0 0 auto;
                }
            }
            .select-header {
                display: flex;
                align-items: center;
                margin-top: 1rem;
                .header-tab {
                    flex: 1;
                    overflow: hidden;
                    .ui.secondary.menu {
                        a {
                            @include font-size($font_body);
                            padding: 1rem 0.5rem;
                            width: 50%;
                            background-color: var(--color_secondary);
                            // color: var(--color_primary);
                        }
                        .active.item {
                            background-color: var(--color_primary);
                            color: var(--color_secondary);
                        }
                    }
                }
                .header-action {
                    flex: 0 0 auto;
                }
            }
            .select-list {
                height: 40vh;
                border-radius: 15px;
                margin-top: 1rem;
                padding: 1rem;
                background-color: var(--color_secondary);
                .list-wrapper {
                    height: 100%;
                    width: 100%;
                    overflow: auto;
                    @extend .scrollbar-custom;

                    .item-select-attendee {
                        display: flex;
                        align-items: center;
                        padding: 1rem 0;
                        cursor: pointer;

                        .isa-checkbox {
                            flex: 0 0 auto;
                            margin-right: 1rem;
                        }
                        .isa-text {
                            flex: 1;
                            overflow: hidden;
                            .text-name {
                                margin-bottom: 0.25rem;
                                // font-weight: bold;
                            }
                            .text-email {
                                color: var(--color_detail);
                            }
                        }
                        .isa-action {
                            flex: 0 0 auto;
                            margin-left: 1rem;
                            padding-right: 1rem;
                        }
                    }
                    .item-select-attendee {
                        // margin-bottom: 1rem;
                        border-bottom: 1px solid var(--color_primary);
                        &:last-child {
                            border-bottom: none;
                        }
                    }

                    .box-group {
                        padding-right: 1rem;
                        .item-group {
                            border: 1px solid var(--color_primary);
                            border-radius: 15px;
                            margin-bottom: 0.5rem;
                            .content {
                                padding: 0 1rem;
                            }
                        }
                    }
                }
            }
        }

        .box-action {
            margin-top: 1rem;
            display: flex;
            .action-left {
                flex: 0 0 auto;
            }
            .action-right {
                flex: 1;
                overflow: hidden;
                text-align: right;
            }
        }
    }
    .modal-attendee-add-new-email {
        p {
            margin-top: 0.5rem;
            padding-left: 1em;
            color: var(--color_detail);
        }
    }
}
