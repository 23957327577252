$size_height_header_sm: 50px;
$size_height_header_lg: 60px;

$size_height_footer_mobile: 60px;

// Extra small (xs)
// Small (sm)
// Medium (md)
// Large (lg)
// Extra large (xl)
// Extra extra large (xxl)

@mixin media-sm {
    @media screen and (max-width: 575.98px) {
        @content;
    }
}
@mixin media-md {
    @media screen and (max-width: 767.98px) {
        @content;
    }
}
@mixin media-lg {
    @media screen and (max-width: 991.98px) {
        @content;
    }
}
@mixin media-xl {
    @media screen and (max-width: 1199.98px) {
        @content;
    }
}
@mixin media-xxl {
    @media screen and (max-width: 1399.98px) {
        @content;
    }
}

//Min-width
// @mixin media-sm {
//     @media screen and (min-width: 576px) {
//         @content;
//     }
// }
// @mixin media-md {
//     @media screen and (min-width: 768px) {
//         @content;
//     }
// }
// @mixin media-lg {
//     @media screen and (min-width: 992px) {
//         @content;
//     }
// }
// @mixin media-xl {
//     @media screen and (min-width: 1200px) {
//         @content;
//     }
// }
// @mixin media-xxl {
//     @media screen and (min-width: 1400px) {
//         @content;
//     }
// }

@mixin media($keys...) {
    @each $key in $keys {
        @if ($key == mobile) {
            @include media-sm {
                @content;
            }
        } @else if ($key == tablet) {
            @include media-lg {
                @content;
            }
        } @else if ($key == tablet-landscape) {
            @include media-lg {
                @content;
            }
        } @else if ($key == tablet-portrait) {
            @include media-lg {
                @content;
            }
        } @else if ($key == desktop) {
            @include media-xl {
                @content;
            }
        } @else if ($key == big-desktop) {
            @include media-xxl {
                @content;
            }
        }
    }
}
