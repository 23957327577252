.module.viewCreateEvent {
    @include gap-header(0);
    @include gap-footer(0px);
    .create-event-header {
        padding: 2rem 0;
        text-align: center;
        > * {
            font-weight: bold;
        }
    }
    .create-event-body {
        .location-box {
            overflow: hidden;
            background-color: var(--color_secondary);
            border-radius: 20px;
            padding: 2rem 1rem;
            > p {
                padding-left: 1rem;
            }
            .room-list {
                padding: 1rem;
                max-height: 40vh;
                overflow: auto;
                @extend .scrollbar-custom;
                @include media-sm {
                    max-height: 30vh;
                }
                .item-room {
                    // padding: 1rem;
                    margin-bottom: 1rem;
                    > * {
                        box-shadow: 0 4px 8px 0 var(--color_box_shadow);
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .box-action {
            button {
                min-width: 100px;
            }
        }
    }
}
