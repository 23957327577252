.module.layoutBlank {
    width: 100%;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    position: relative;
    // height: 100vh;
    // height: calc(var(--vh, 1vh) * 100);
    // background-color: var(--color_body);
    // height: 100%;
}
