.module.viewMenu {
    // width: 300px;
    // overflow: hidden;
    .menu-wrapper {
        @include gap-header(0);
        @include gap-footer(0px);
        .menu-header {
            padding-top: 50px;
            margin-bottom: 30px;
            text-align: center;
            > * {
                font-weight: bold;
            }
        }

        .menu-user {
            cursor: pointer;
            background-color: var(--color_secondary);
            padding: 2rem;
            border-radius: 20px;
            margin-bottom: 1rem;
            .user-box {
                display: flex;
                align-items: center;
                img {
                    flex: 0 0 auto;
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    overflow: hidden;
                    object-fit: cover;
                    margin-right: 2rem;
                }
                .user-info {
                    flex: 1;
                    overflow: hidden;
                    > * {
                        margin: 0;
                    }
                    .text-header {
                        font-weight: bold;
                        @include font-size($font_body_header);
                    }
                }
            }
        }

        .menu-list {
            .menu-list-box {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 1rem;
                .item-menu {
                    .item-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        flex-direction: column;
                        background-color: var(--color_secondary);
                        padding: 2rem;
                        border-radius: 20px;
                        color: inherit;
                        img {
                            width: 30px;
                            height: 30px;
                            margin-bottom: 1rem;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}
