.module.viewScanQRCode {
    @include gap-header(0);
    @include gap-footer(0px);
    .view-scan-qr-code-header {
        padding-top: 50px;
        margin-bottom: 30px;
        text-align: center;
        > * {
            font-weight: bold;
        }
    }
    .view-scan-qr-code-body {
        display: flex;
        justify-content: center;
        .qr-code-camera {
            width: 400px;
            overflow: hidden;
            flex: 0 0 auto;
            border: 5px solid var(--color_detail);
            border-radius: 5px;
            @include media-sm {
                width: 100%;
            }
            video {
                object-fit: cover;
            }
            // img {
            //     width: 100%;
            // }
        }
    }
}
